@import "font-awesome/scss/font-awesome";

* {
    padding:0px;
    margin: 0px;
    outline: 0px none;
    /*font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;*/
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    &::before, &::after{
    box-sizing: border-box;
    }
}

a{
    color:#5BA0DA;
}

.dark-text{
    color: #404040;
    font-weight: 700;
}
h5, .social-icons{
    border: 0px none;
    font-family: inherit;
    font-size:100%;
    font-style: inherit;
    vertical-align: baseline;
}
h5{
    font-size:20px;
}

.profile{
    position:relative;
    border-radius: 4px;
    overflow:hidden;
    margin-bottom: 35px;

    .name{
        display:inline-block;
        text-align: center;
        padding:20px 0px 15px 0px;
        width: 100%;
    }

    .profile-pic{
        border-radius: 50%;
        width: 174px;
        height: 174px;
        margin: auto auto 25px;
        overflow: hidden;
    }


    .profile-pic img{
        width:100%;
        height:100%;
        vertical-align: middle;
        border: 0px none;
    }

    .details{
        display: inline-block;
        padding-bottom:5px;
        text-align: center;
        font-size:13px;
        line-height:20px;
        padding: 15px;
        width:100%;
        transition: all 500ms ease 0s;

        .position{
            font-size: 16px;
        }
    }


    .extended-details{
        width:50%;
        margin:0 auto;
    }

    .social-icons{
        margin:25px auto;
        width:354px;
        ul{
           list-style: outline none disc;
           padding-left: 0px;
           li{
               display: inline-block;
               line-height: 32px;
               margin: 6px;
               a{
                   font-size:20px;
                   border-radius: 16px;
                   text-align:center;
                   width: 32px;
                   height: 32px;
                   display:block;
                   border:solid 1px #C9C9C9;
                   transition: border-color 1s ease;
                   transition: background-color 1s ease;

                   &:hover{
                       border-color: #00BFFF;
                       background-color: #EFEFEF;
                   }
               }
           }
       }
    }
    .system-information{
        text-align: center;
        font-size: 12px;
        a{
            font-weight: bold;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
